@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.pixel-font {
    font-family: 'Press Start 2P', cursive;
    color: #00fc1dcc;
}

.top-text {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0em; /* The typwriter cursor */
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
        typing 2s steps(30, end),
        blink-caret 1s step-end infinite;
}

.download-link:hover {
    animation: blink 1s infinite;
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.card-body {
    white-space: pre-wrap;
}

.port-img {
    width: 400px;
    height: 250px;
}

.slide-img {
    width: 200px;
    height: 150px;
}

.slide-img-java {
    width: 150px;
    height: 150px;
}

.slide-img-py {
    width: 200px;
    height: 170px;
}

.slide-img-tf {
    width: 150px;
    height: 150px;
}

::selection {
    color: rgb(255, 0, 200);
    background: rgb(3, 3, 3);
}

.pink-hover:hover {
    color: rgb(255, 0, 200);
}

.name {
    font-size: 30px;
}

.comptia-img, .aws-dev-img {
    height: 320px;
    width: 320px
}

@media screen and (min-width: 1300px) {

    .card-deck {
        width: 80%;
        left: calc(10%);
    }

    .single-card {
        width: 91.666667%;
    }

    .card-img {
        grid-column: span 2 / span 2;
    }

    .card-text {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        grid-column-start: 3;
        grid-column: span 2 / span 2;
    }

    .card-title {
        font-size: 1.5rem;
        line-height: 2rem;
        height: 5rem;
    }

    .card-body {
        padding-left: 1rem;
    }

    .resume-header {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }

    .deck-stack {
        padding-top: 6rem;
    }

    .slug-32-lg {
        height: 128px;
    }

    .swiper-container {
        height: 12rem;
    }

    .big-bullet {
        height: 3.5rem;
    }

}

@media screen and (min-width: 600px) and (max-width: 1300px) {
    .single-card {
        width: 80%
    }
}

@media screen and (max-width: 1300px) {

    .andrew-pic {
        width: 4rem;
        height: 4rem;
    }

    .card-deck {
        left: 0.5rem;
    }

    .card-img {
        grid-column: span 1 / span 1;
    }

    .card-text {
        grid-column-start: 2;
        grid-column: span 3 / span 3;
    }

    .card-body {
        padding-left: 0.5rem;
        font-size: 0.75rem;
        line-height: 1rem;
    }

    .slug-12-sm {
        height: 3rem;
    }

    .deck-stack {
        padding-top: 4rem;
    }

    .name {
        margin-top: 48px;
        font-size: 15px;
    }

    .deck-title {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .port-img {
        width: 100px;
        height: 80px;
    }

    .card-title {
        font-size: 10px
    }

    .bullet-text {
        font-size: 10px
    }

    .slide-img {
        width: 60px;
        height: 60px;
    }

    .slide-img-java {
        width: 60px;
        height: 60px;
    }

    .slide-img-py {
        width: 70px;
        height: 70px;
    }

    .slide-img-tf {
        width: 60px;
        height: 60px;
    }
}

